.handle_Svg {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.land_content {
  width: 70%;
  display: flex;
  align-items: center;
  p {
    line-height: 2;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
  }
}
