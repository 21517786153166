@import url('https://fonts.googleapis.com/css2?family=Prosto+One&display=swap');
.parent_three {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "K2D",sans-serif;
  .canvas_One {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right , rgba(112, 95, 87,.8),#825b49e9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 3px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-family: "K2D",sans-serif;
  span {
    font-size: 30px;
    color: #eee1e1;
  }
}
.content__Three {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 3px;
  color: white;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  span {
    font-size: 30px;
    color: #eee1e1;
  }
}
.social_Media_sthree {
  position: relative;
  top: 30px;
}
.handle_navBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  font-family: "K2D",sans-serif;
  nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
    ul {
      padding: 0%;
      margin: 0%;
      display: flex;
      justify-content: space-around;
      width: 70%;
      align-items: center;
      transition: 400ms ease;
      li {
        list-style: none;
        font-weight: bold;
        color: white;
      }
    }
    span {
      font-size: 30px;
      position: relative;
      top: 4px;
      width: 100%;
      height: 100%;
    }
  }
}
canvas {
  position: absolute;
}
