body{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    a{
        text-decoration: none;
        color: #231f20;
    }
}