.main_home_land {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, rgb(35, 31, 32, 0), rgb(35, 31, 32, 1)),
    url("../Images/land_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  .handle_navBar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    nav {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-family: "Poppins", sans-serif;
      ul {
        padding: 0%;
        margin: 0%;
        display: flex;
        justify-content: space-around;
        width: 70%;
        align-items: center;
        transition: 400ms ease;
        &:hover {
          transform: scale(1.1);
        }
        li {
          list-style: none;
          font-weight: bold;
          color: rgb(35, 31, 32);
        }
      }
      span {
        font-size: 30px;
        position: relative;
        top: 4px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.motivated_parent {
  width: 64%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  h1 {
    font-family: "Roboto", sans-serif;
    font-size: 100px;
    margin: 0;
    font-weight: bold;
    padding: 0;
    color: #231f20;
    span {
      font-size: 150px;
      position: relative;
      top: 60px;
      left: -70px;
    }
    i {
      font-size: 90px;
      position: relative;
      top: 40px;
      left: -50px;
    }
    .__i {
      position: relative;
      left: -73px;
      top: 70px;
    }
  }
  p {
    font-family: "Roboto";
    font-weight: 600;
    text-align: left;
    position: relative;
    left: -50px;
    color: #231f20;
  }
}
.social_btns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  .social_mediaIcons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
  }
  .social_btns_child {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    position: relative;
    left: -28px;
  }
  .span_icons {
    font-size: 30px;
    transition: 400ms ease-out;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0);
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
      color: #0e0b0b;
    }
  }
  button {
    background: #231f20;
    color: white;
    border: 2px solid white;
    outline: none;
    padding: 8px;
    width: 240px;
    transition: 400ms ease-out;
    border-radius: 50px;
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}
@import "./demoOne.module";
